import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"

import "./service.scss"

import IconArrowRightWhite from "../assets/icon-arrow-right-white.svg"
import SEO from "../mainComponents/seo"
import Wrapper from "../mainComponents/Wrapper"

export const query = graphql`
    query ServiceItemQuery($slug: String!) {
        servicesDataJson(slug: {eq: $slug}) {
            id
            title
            slug
            text
            tech {
                id
                title
                items {
                    id
                    icon
                    title
                }
            }
            created
            next {
                title
                link
            }
        }
    }
    
`;

export default ({ data }) => {
    const service = data.servicesDataJson;

    return (
        <Wrapper>
        <Layout>
            <SEO title={`${service.title}`} description={service.text}/>

            <section className="service">
                <div className={`${service.slug} container`}>
                    <h2 className="service__title">{service.title}</h2>

                    <p className="service__text">{service.text}</p>

                    {service.tech ? <div className="service-tech">
                        {service.tech.map(techBlock =>
                            <div className="service-tech__block" key={techBlock.id}>
                                <h4 className="service-tech__title">{techBlock.title}</h4>

                                <div className="service-techList">
                                    {techBlock.items.map(techElem =>
                                        <div className="service-techList__item" key={techElem.id}>
                                            <img  className="service-techList__icon" src={techElem.icon} alt={techElem.title}/>
                                            <h6  className="service-techList__name">{techElem.title}</h6>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div> : null}

                    {service.created ? <>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>

                        <ul className="service-help">
                            {service.created.map((help, index) =>
                                <li className="service-help__item" key={index}>{help}</li>
                            )}
                        </ul>
                    </> : null}

                    <Link className="service-link" to={`/services${service.next.link}/`}>
                        <span className="service-link__span">{service.next.title}</span>
                        <img className="service-link__arrow" src={IconArrowRightWhite} alt=""/>
                    </Link>
                </div>
            </section>
        </Layout>

        <Footer>
            <FooterMail/>
        </Footer>
        </Wrapper>
    )
}
